<template>
  <div class="top">
    <div class="header">
      <div class="headerimage" />
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <!--        <div>-->
        <!--          <img src="@/assets/images/pleisure/pleisure_headerimage.jpg" />-->
        <!--        </div>-->
        <div class="col-lg-12 rowSpace"></div>
        <div class="text-center col-lg-12 mainTitle">그린사이드BBQ&힐링</div>
        <div class="mainTitleLine">
          <hr />
        </div>
        <div class="col-lg-12 text-center mainIntro">
          초록 잔디 위 특별한 만찬과 프리미엄 스파까지 로맨틱한 저녁의 완성
        </div>
        <div class="rowSpace"></div>
        <div class="col buttonArea">
          <router-link to="/p/Pleisure" class="prev-button">이전 </router-link>
          <router-link to="/p/Reservpleisure/8" class="resserve-button"> 예약신청 </router-link>
        </div>
        <div class="rowSpace"><hr /></div>
        <GallerySlideCarousels :slides="main_slides" :slide-count="3" :thumb-slide-width="45" />
      </div>
    </div>
    <div class="container subContainer">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 mainInfo">
          <div>야외 BBQ 특선 코스와 프리미엄 뷰티 스파를 함께 즐길 수 있는 프로그램 입니다.</div>
          <div style="word-break: keep-all">
            한국 최초의 세계 100대 골프장에 선정된 핀크스 골프클럽의 빼어난 코스 전망을 감상하며 초록 잔디 위에서
            펼쳐지는 로맨틱한 다이닝 만찬을 즐기고, 전문 테라피스트의 프리미엄 케어를 통해 편안한 휴식과 힐링을 경험
            하세요.
          </div>
          <div>※ 그린사이드BBQ 운영시간 : 18:00~21:00(주말운영)</div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">공통 의전 및 서비스</div>
          <div class="subDatail">
            <div>- 차량 의전 서비스 (벤츠 스프린터 / 카니발 하이리무진)</div>
            <div>- 핀크스 의전 담당 매니저 케어</div>
            <div>- 어메니티, 미니바 제공</div>
          </div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">메인 프로그램</div>
          <div class="subDatail">
            <div>- 그린사이드BBQ : 핀크스 골프클럽의 특별한 프로그램으로 초록 잔디위에서 즐기는 로맨틱한 만찬</div>
            <div>- 뷰티스파 : 힐링 온천수 제품을 이용하여 건강하고 맑은 피부 관리, 피로를 풀어주는 테라피 진행</div>
          </div>
          <div class="rowSpace"></div>
          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>옵션</td>
                <td>최대인원</td>
                <td>소요시간</td>
                <td>요금(2인 기준)</td>
                <td>추가옵션</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>그린사이드BBQ특선코스 + 와인 1BT + 뷰티스파(90분)</td>
                <td>4인</td>
                <td>4H</td>
                <td>1,100,000</td>
                <td>※ 인원 추가 +450,000원/인</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 serviceArea">
          <div class="subDetail">
            <div>※ 5세 이상부터 1인 요금이 적용됩니다.</div>
            <div>※ 경우에 따라 미성년자의 경우에는 보호자 동반이 필요할 수 있습니다.</div>
            <div>※ 단순 고객변심에 의한 취소/환불은 업체 위약 규정에 따라 적용됩니다.</div>
            <div>
              - 7일 전 위약금 0%, 6일 전 ~ 5일 전 30%, 4일 전 50%, 3일 전~2일 전(~17시) 70%, 2일전(17시~) ~ 당일 100%
              위약금 부과
            </div>
            <div>※ 천재지변 및 업체의 운영 상 부득이한 상황으로 취소 시, 위약 규정이 적용되지 않습니다.</div>
            <div>※ 여행자보험 포함 상품입니다.</div>
            <div>※ 요금표 외 기타 인원은 전화 문의 바랍니다.</div>
          </div>
        </div>
        <div class="rowSpace"></div>
      </div>
    </div>
  </div>
</template>

<script>
import GallerySlideCarousels from '@/components/GallerySlideCarousels';

export default {
  name: 'grbbqandspa',
  data() {
    return {
      main_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage13.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage13-2.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage13-3.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage13-4.jpg')},
      ],
      addition_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage07.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage08.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage09.jpg')},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '그린사이드BBQ&스파'});
  },
  components: {GallerySlideCarousels},
};
</script>

<style scoped lang="scss">
td {
  vertical-align: middle;
}
.top {
  padding-top: 11rem;
}
.mainTitle {
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: -0.1rem;
}
.mainTitleLine {
  width: 10%;
  > hr {
  }
}
.mainIntro {
}
.buttonArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .resserve-button {
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: #524a3d;
    color: #fff;
  }
  .prev-button {
    justify-content: center;
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: white;
    color: #000;
    border: 1px solid black;
  }
}
.subContainer {
  width: 100%;
}
.mainInfo {
  padding: 1rem;
  width: 100%;
  height: 100%;
  > div {
    line-height: 1.5rem;
    font-size: 1rem;
  }
}
.serviceArea {
  padding: 1rem;
  > span {
    font-size: 0.8rem;
  }
}
.subTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.subDetail {
  font-size: 0.9rem;
  padding-left: 1rem;
}
.subTable {
  > thead {
    background-color: #eaeaea;

    > tr > td {
      text-align: center;
      font-weight: 600;
    }
  }
  > tbody {
    > tr > td {
      text-align: center;
      font-size: 0.9rem;
    }
  }
}

.rowSpace {
  width: 100%;
  height: 2rem;
  > hr {
  }
}

.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/pleisure/pleisure_headerimage.jpg');
}

@media (max-width: 992px) {
  .top {
    padding-top: 5rem;
  }

  .headerimage {
    display: none;
  }
}
</style>
